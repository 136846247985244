import styled from "styled-components";
import Atlas from "../components/Atlas/Atlas.js";

export default function Home() {

    return (
        <>
            <Atlas />
        </>
    )
}
