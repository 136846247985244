import brain from "../../images/organs/Brain_icon.svg"
import blood from "../../images/organs/Blood_icon.svg"
import colon from "../../images/organs/Colon_icon.svg"
import heart from "../../images/organs/Heart_icon.svg"
import kidney from "../../images/organs/Kidney_icon.svg"
import liver from "../../images/organs/Liver_icon.svg"
import lungs from "../../images/organs/Lungs_icon.svg"
import lymphnode from "../../images/organs/Lymphnode_icon.svg"
import nasopharynx from "../../images/organs/Nasopharynx_icon.svg"
import testis from "../../images/organs/Testis_icon.svg"
import trachea from "../../images/organs/Trachea_icon.svg"

function getImage(organ) {
    const img = new Image();
    img.src = organ;
    return img;
  }

export const initialOptions = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            min: -8.0,
            max: 8.0,
            grid: {
                display: false,
            },
        },
        y: {
            position: "center",
            grid: {
                display: false,
            },
            ticks: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        annotation: {
            animations: {
                numbers: {
                    properties: ['x'],
                    type: 'number'
                },
            },
            annotations: {
                annotationBrain: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(brain),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Brain",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationBrain"
                },
                annotationNasopharynx: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(nasopharynx),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Nasopharynx",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationNasopharynx"
                },
                annotationTrachea: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(trachea),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Trachea",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationTrachea"
                },
                annotationHeart: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(heart),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: -1.5,
                    yValue: "Heart",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationHeart"
                },
                annotationLungs: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(lungs),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Lungs",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationLungs"
                },
                annotationLiver: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(liver),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Liver",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationLiver"
                },
                annotationKidney: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(kidney),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Kidney",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationKidney"
                },
                annotationColon: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(colon),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Colon",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationColon"
                },
                annotationTestis: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(testis),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Testis",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationTestis"
                },
                annotationBlood: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(blood),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Blood",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationBlood"
                },
                annotationLymphnode: {
                    type: "label",
                    drawTime: "beforeDatasetsDraw",
                    content: getImage(lymphnode),
                    display: false,
                    position: {
                        x: "center",
                        y: "center"
                    },
                    xValue: 1.5,
                    yValue: "Lymphnode",
                    callout: {
                        display: true,
                        position: "left"
                    },
                    id: "annotationLymphnode"
                }
            }
        }
    }
};