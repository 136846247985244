import styled from "styled-components";


export default function TitleBox({ text, className }) {
    return (
        <>
            <div className={className}>
                <StyledContainer>
                    <h1>{text}</h1>
                </StyledContainer>
            </div>
        </>
    )
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10%;

    h1{
        font-size: 25px;
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
        color: #5C5C5C;
    }
`