import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import Anatogram from "../Anatogram/Anatogram";
import { useEffect } from "react";
import ExpressionCombinedViz from "../ExpressionCombinedViz/EpressionCombinedViz";
import { useMediaQuery } from "@mui/material";
import TextBox from "../TextBox/TextBox";

export default function GeneExpressionPanel({ className, selectedData, isMobile, geneInfo, loadState}) {
    const [value, setValue] = useState("2");
    const [minPanelHeight, setMinPanelHeight] = useState('525px');
    const [panelPadding, setPanelPadding] = useState('0px');

    useEffect(() => {
        if(isMobile){
            setValue("1")
            setMinPanelHeight('600px')
            setPanelPadding('auto')
        }
    },[isMobile])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%"}}>
            <TabContext value={value}>
                {isMobile?<TabPanel value="1" sx={{padding:panelPadding, minHeight:minPanelHeight}}><ExpressionCombinedViz selectedData={selectedData}/></TabPanel>:null}
                <TabPanel value="2" sx={{padding:panelPadding,  minHeight:minPanelHeight}}><Anatogram className={className} selectedData={selectedData} showBiologicalSexToggleButton={false}/></TabPanel>
                <TabPanel value="3"sx={{padding:panelPadding, minHeight:minPanelHeight}}><TextBox data={selectedData?.gene} loadState={loadState} className={"text-box"}/></TabPanel>
                <Box sx={{ borderTop: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="gene expression panel tabs" centered TabIndicatorProps={{
                        sx: {
                            top: '0%'
                        }
                    }}>
                        {isMobile?<Tab label="Expression" value="1" />:null}
                        <Tab label="Body" value="2" />
                        <Tab label="Gene info" value="3" />
                    </TabList>
                </Box>
            </TabContext>
        </Box>
    );
}