import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components";
import BarChart from "../BarChart/BarChart";

export default function ExpressionCombinedViz({ selectedData}) {

    return (
        <StyledContainer>
            {/* <OrgansHeatmap className={"organs-heatmap"} selectedData={selectedData} /> */}
            <BarChart className={"horizontal-bar-chart"} selectedData={selectedData} isMobile={true}/>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    @media(max-width:600px){
        display: flex;
        
        .horizontal-bar-chart{
            margin: 0px auto;
        }
    }
`