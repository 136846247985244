import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function Trachea({ children, text, fill, stroke }) {

    const [style, setStyle] = useState({
        stroke: "#bdbfc1",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256, 
            fill: fill
        })
    }, [fill, stroke])

    const tooltipText = <p>Trachea<br />{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg1299"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs1157">
                    <style type="text/css" id="style1155">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style>
                </defs>
                <g id="Layer_x0020_1" transform="translate(-15456.04 -8995.77)">
                    <g id="_2033465628064">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={9654.87}
                            r={623.82}
                            id="circle1283"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M16060.28 9909.7c.36-100.26.73-200.51-4.04-262.21-4.78-61.69-14.69-84.83-23.87-102.09-9.18-17.26-17.63-28.65-28.1-41.5-10.46-12.85-22.95-27.17-31.58-46.09-8.63-18.91-13.4-42.41-12.67-58.2.74-15.79 6.98-23.87 13.22-29.57 6.25-5.69 12.49-8.99 19.47-8.07 6.97.91 14.69 6.06 18.36 15.42s3.3 22.95 4.59 33.42c1.28 10.46 4.22 17.81 10.65 23.5 6.42 5.69 16.34 9.73 22.58 12.85 6.25 3.13 8.82 5.33 12.12 11.02 3.31 5.69 7.35 14.87 15.61 24.24 8.26 9.36 20.75 18.91 32.32 23.13 11.56 4.23 22.21 3.13 31.21.19s16.34-7.71 23.69-12.49"
                            id="path1285"
                        />
                        <path
                            className="fil2 str1"
                            d="M16184.77 9448.08c8.81 0 17.63 0 26.07-3.67 8.45-3.67 16.53-11.02 20.94-21.67 4.4-10.65 5.14-24.6 5.87-34.7.74-10.1 1.47-16.34 5.51-20.75s11.39-6.98 18.18-5.14c6.79 1.84 13.04 8.08 16.34 19.65 3.31 11.56 3.67 28.46 3.31 43.7-.37 15.24-1.47 28.83-9.55 44.07-8.08 15.24-23.14 32.13-36.18 47.92-13.03 15.79-24.05 30.48-31.39 48.84-7.35 18.36-11.02 40.4-13.04 62.43-2.02 22.04-2.39 44.07-2.2 80.79.18 36.73.91 88.14 1.28 124.5.37 36.35.37 57.65.37 78.95"
                            id="path1287"
                        />
                        <path
                            className="fil2 str1"
                            d="M16189.91 9834.05c-4.65 4.65-9.3 9.3-17.32 13.28-8.02 3.98-19.4 7.28-32.26 8.38-12.85 1.11-27.17 0-41.49-1.1"
                            id="path1289"
                        />
                        <path
                            className="fil2 str1"
                            d="M16059.94 9744.4c7.82 4.42 15.65 8.84 24.15 11.42 8.5 2.58 17.69 3.31 27.05 3.68 9.36.36 18.91.36 28.46.36"
                            id="path1291"
                        />
                        <path
                            className="fil2 str1"
                            d="M16189.25 9652.96c-5.9 2.21-11.8 4.43-19.89 6.64s-18.38 4.42-27.92 5.33c-9.55.92-18.37.56-25.89-.18-7.53-.73-13.77-1.83-20.02-2.94"
                            id="path1293"
                        />
                        <path
                            className="fil2 str1"
                            d="M16090.02 9556.05c10.29 2.94 20.57 5.88 30.3 6.24 9.73.37 18.91-1.83 25.16-3.85 6.24-2.02 9.54-3.86 12.85-5.7"
                            id="path1295"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}