import { useEffect, useState } from "react";
import styled from "styled-components";
import BarChart from "../BarChart/BarChart.js";
import GeneSelectionBox from "../SelectionBox/GeneSelectionBox.js";
import dataStructured from "../../data/v2/dataStructured.json"
import csblLogo from "../../images/CSBL_Logo.png"
import Logo from "../Logo/Logo.js";
import TitleBox from "../TitleBox/TitleBox.js";
import GeneExpressionPanel from "../GeneExpressionPanel/GeneExpressionPanel.js";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getGeneInfo } from "../../services/genes-info-service.js";
import OrgansHeatmap2 from "../OrgansHeatmap/OrgansHeatmap/OrgansHeatmap.js";

export default function Atlas() {
    const [genes, setGenes] = useState([])
    const [selectedGene, setSelectedGene] = useState(null)
    const [selectedGeneInfo, setSelectedGeneInfo] = useState(null)
    const [loadState, setLoadState] = useState("initial")
    const [selectedData, setSelectedData] = useState(null)
    const [initialLoad, setInitialLoad] = useState(true)
    const isMobile = useMediaQuery('(max-width:600px)', {noSsr: true})

    useEffect(() => {
        const genesAvailableToSelect = dataStructured.map((gene) => {
            return gene.gene
        })
        setGenes(genesAvailableToSelect)
        setSelectedGene('A2ML1')
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        setSelectedGene(genes[0])
    }, [initialLoad])

    useEffect(() => {
        console.log(isMobile)
        let filteredData
        if (selectedGene) {
            setLoadState("loading")
            filteredData = dataStructured.filter(element => {
                return element.gene === selectedGene
            })
            setSelectedData(filteredData[0])
            // getGeneInfo(selectedGene)
            //     .then(res => {
            //             if(res.data.length>0){
            //                 setSelectedGeneInfo({ ...res.data[0] })
            //                 setLoadState("ready")
            //             }else{
            //                 setLoadState("ready-not-found")
            //             }
            //     })
            //     .catch(err => {
            //         console.log(err)
            //         setSelectedGeneInfo(null)
            //         setLoadState("fail")
            //     })
        }
    }, [selectedGene])

    console.log(isMobile)
    return (
        <>
            
            <StyledContainer>
                <Logo src={csblLogo} className={"logo"} />
                <GeneSelectionBox className={"selection-box"} genes={genes} setSelectedGene={setSelectedGene} />
                {isMobile ? null : <OrgansHeatmap2 className={"organs-heatmap"} selectedData={selectedData} />}
                {isMobile ? null : <BarChart className={"horizontal-bar-chart"} selectedData={selectedData} isMobile={isMobile}/>}
                <div className={"anatogram-column"}>
                    <TitleBox text={selectedGene} className={"gene-title-box"} />
                    {/* <Anatogram className={"anatogram"} selectedData={selectedData}/> */}
                    <GeneExpressionPanel className={"anatogram"} selectedData={selectedData} isMobile={isMobile} geneInfo={selectedGeneInfo} loadState={loadState}/>
                    {/* <TextBox className={"text-box"} text={"Nulla nulla augue, fermentum eu mollis quis, ultricies nec arcu. Cras ut arcu fringilla, ornare elit at, suscipit neque. Phasellus non scelerisque elit. Donec et tempor massa."} /> */}
                </div>
            </StyledContainer>
        </>
    )
}

const StyledContainer = styled.div`
    width:50vw;
    margin: 40px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-auto-rows: auto 1fr;
    row-gap: 20px;

    .selection-box{
        grid-column: 3;
    }

    .organs-heatmap{
        grid-column: 2;
    }

    .horizontal-bar-chart{
        grid-column: 3;
    }

    .anatogram-column{
        grid-column: 1;
        grid-row: 2;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .gene-title-box{
        width: 100%;
    }

    @media (max-width:600px){
        margin: 20px auto 20px auto;
        width: 95%;

        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        row-gap: 20px;
        
        .selection-box{
            grid-column: 1;
            grid-row: 2;
        }

        .anatogram-column{
            grid-column: 1;
            grid-row: 3;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .selection-box{
            display:flex;
            justify-content: center;
            align-items: center;
        }

        .gene-title-box{
            display: none;
        }
    }
`