import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function Liver({ children, text, fill, stroke }) {
    
    const [style, setStyle] = useState({
        stroke: "#bdbfc1",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256, 
            fill: fill
        })
    }, [fill, stroke])

    const tooltipText = <p>Liver<br />{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg154"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs20">
                    <style type="text/css" id="style18">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style>
                </defs>
                <g id="Layer_x0020_1" transform="translate(-15456.04 -13118.31)">
                    <g id="_2033609457040">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={13777.41}
                            r={623.82}
                            id="circle146"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M16415.64 13483.51c17.32-.66 22.21 6.44 23.1 30.88.89 24.43-2.22 66.18-10.22 103.05-7.99 36.87-20.88 68.86-36.65 94.62-15.77 25.77-34.42 45.31-55.97 62.19-21.54 16.88-45.97 31.1-68.18 50.42-22.21 19.32-42.2 43.75-60.86 65.96-18.66 22.22-35.98 42.2-55.08 60.64-19.1 18.43-39.98 35.31-62.41 51.53-22.44 16.21-46.43 31.76-71.3 44.42-24.88 12.66-50.64 22.43-71.74 28.43-21.1 6-37.54 8.22-49.09-2.44-11.55-10.67-18.21-34.21-24.65-61.3-6.44-27.1-12.66-57.75-18.44-91.51-5.77-33.76-11.1-70.63-10.44-102.84.67-32.2 7.33-59.75 17.33-86.4 9.99-26.65 23.32-52.42 39.31-73.52 15.99-21.1 34.65-37.53 58.86-53.74 24.21-16.22 53.97-32.21 85.29-43.09 31.32-10.89 64.19-16.66 95.28-22.21 31.1-5.56 60.42-10.89 93.29-17.11 32.87-6.22 69.3-13.32 102.39-21.1 33.09-7.77 62.86-16.21 80.18-16.88z"
                            id="path148"
                        />
                        <path
                            className="fil2 str1"
                            d="M16186.45 13530.17c12.13 6.73 24.27 13.46 33.89 24.6 9.62 11.14 16.73 26.69 22.95 45.57 6.21 18.88 11.54 41.09 15.1 62.64 3.55 21.54 5.33 42.42 5.77 63.74.45 21.32-.44 43.09-3.13 62.4-2.69 19.31-7.18 36.17-11.67 53.03"
                            id="path150"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}