export function valueToColor(value, negativeHue = 240, positiveHue = 0, alpha = 0.5){
    let color;
    let lightness;
    switch (true) {
        case value < 0:
            lightness = 100 + (value/(-5.0))*(-50);
            color = `hsl(${negativeHue}, 100%, ${lightness}%,  ${alpha})`;
            break;
        case value > 0:
            lightness = 100 + (value/(5.0))*(-50)
            color = `hsl(${positiveHue}, 100%, ${lightness}%, ${alpha})`;
            break;
        case value = 0:
            color = `hsl(0, 100%, 100%, ${alpha})`;
            break;
    }

    return color
}