import GlobalStyle from './globalStyles.js';
import Home from './pages/Home.js';

function App() {
  return (
    <>
    <GlobalStyle/>
    <Home/>
    </>
  );
}

export default App;
