import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function Nasopharynx({ children, text, fill, stroke }) {

    const [style, setStyle] = useState({
        stroke: "#bdbfc1",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256, 
            fill: fill
        })
    }, [fill, stroke])
    const tooltipText = <p>Nasopharynx<br />{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg550"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs412">
                    <style type="text/css" id="style410">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style>
                </defs>
                {"sssssssssssssssssssssssss"}
                <g id="Layer_x0020_1" transform="translate(-15456.04 -7621.59)">
                    <g id="_2033469704464">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={8280.69}
                            r={623.82}
                            id="circle538"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M16140.17 7994.22c19.07 106.67 38.14 213.33 47.53 271.28 9.38 57.95 9.08 67.19 6.85 77.17-2.24 9.98-6.41 20.71-13.41 30.69s-16.83 19.22-28.16 25.48c-11.32 6.25-24.13 9.53-37.09 10.27-12.96.75-26.07-1.04-38.73-5.8-12.67-4.77-24.88-12.52-33.08-21.9-8.19-9.39-12.36-20.41-15.64-28.61-3.28-8.19-5.66-13.55-14-13.41-8.34.15-22.65 5.81-36.95 11.48"
                            id="path540"
                        />
                        <path
                            className="fil2 str1"
                            d="M16249.22 8358.91c-13.41-7.15-26.81-14.3-35.76-17.93-8.96-3.63-13.45-3.73-17.95-3.84"
                            id="path542"
                        />
                        <path
                            className="fil2 str1"
                            d="M16231.35 8411.65c7.74.3 15.49.59 25.62-2.53 10.13-3.13 22.64-9.69 32.62-17.43 9.99-7.75 17.43-16.69 22.8-27.71 5.36-11.03 8.64-24.14 10.13-35.46 1.49-11.32 1.19-20.86-2.09-30.69-3.27-9.83-9.53-19.96-16.38-27.71-6.86-7.74-14.31-13.11-21.76-18.47"
                            id="path544"
                        />
                        <path
                            className="fil2 str1"
                            d="M15935.48 8248.97c-7.74 7.15-15.49 14.3-22.49 22.94-7.01 8.64-13.26 18.77-16.39 27.56-3.13 8.79-3.13 16.24-2.24 26.37.9 10.13 2.69 22.94 7.75 33.96 5.07 11.03 13.41 20.27 22.05 27.42 8.64 7.15 17.58 12.21 28.01 15.79 10.42 3.57 22.34 5.66 34.26 7.74"
                            id="path546"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}