import Tooltip from '@mui/material/Tooltip';
import { useState, useEffect } from 'react';

export default function Blood({ children, text, fill, stroke }) {

    const [style, setStyle] = useState({
        stroke: "#bdbfc1",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256,
            fill: fill
        })
    }, [fill, stroke])

    const tooltipText = <p>Blood<br />{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg150"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs4">
                    <style type="text/css" id="style2">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style>
                </defs>
                <g id="Layer_x0020_1" transform="translate(-15456.04 -18615.041)">
                    <g id="_2031582871600">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={19274.141}
                            r={623.82}
                            id="circle130"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M16101 19281.72c20.78 8.64 48.35 21.4 72.01 40.12 23.66 18.72 43.41 43.41 56.16 66.45 12.76 23.04 18.52 44.44 18.31 66.66-.2 22.22-6.37 45.26-23.45 63.98-17.07 18.72-45.05 33.12-74.47 41.35-29.42 8.23-60.28 10.29-96.7 5.76-36.41-4.52-78.38-15.63-114.59-36.41-36.21-20.78-66.66-51.23-84.15-79.21-17.48-27.98-22.01-53.49-18.1-76.53s16.25-43.61 31.27-59.04 32.71-25.72 54.73-33.33c22.01-7.62 48.34-12.55 70.77-15.02 22.42-2.47 40.94-2.47 57.19-.21 16.26 2.27 30.25 6.79 51.02 15.43z"
                            id="path132"
                        />
                        <path
                            className="fil2 str1"
                            d="M16075.7 19362.78c11.11 4.12 23.45 9.46 33.94 18.1 10.5 8.65 19.14 20.58 20.37 31.69 1.24 11.11-4.94 21.39-17.9 27.77-12.96 6.38-32.71 8.85-52.46 6.38-19.75-2.47-39.5-9.88-54.93-20.16-15.43-10.29-26.54-23.46-31.27-35.18-4.73-11.73-3.09-22.02 5.35-28.6 8.43-6.58 23.66-9.46 35.8-10.49 12.13-1.03 21.19-.21 30.65 1.64 9.46 1.86 19.34 4.74 30.45 8.85z"
                            id="path134"
                        />
                        <path
                            className="fil2 str1"
                            d="M16194.2 19438.08c-1.65 12.75-3.29 25.51-9.46 36-6.17 10.49-16.87 18.72-29.83 24.28-12.96 5.55-28.19 8.43-41.56 10.08-13.37 1.64-24.9 2.06-36.42 2.47"
                            id="path136"
                        />
                        <path
                            className="fil2 str1"
                            d="M16037.64 19487.87c-6.38-3.71-15.02-3.3-18.52 2.26-3.5 5.55-1.85 16.25 2.88 22.63 4.73 6.38 12.55 8.43 18.11 6.79 5.55-1.65 8.84-7 8.43-13.58-.41-6.58-4.52-14.4-10.9-18.1z"
                            id="path138"
                        />
                        <path
                            className="fil2 str1"
                            d="M16337.39 19158.49c.41 7.61-5.35 17.07-15.84 22.42-10.49 5.35-25.72 6.59-41.56 4.53-15.84-2.06-32.3-7.41-41.97-12.96-9.67-5.56-12.55-11.32-10.08-17.49 2.47-6.17 10.29-12.75 21.4-17.28 11.11-4.53 25.51-7 38.47-6.38 12.96.62 24.48 4.32 33.53 9.05 9.06 4.74 15.64 10.5 16.05 18.11z"
                            id="path140"
                        />
                        <path
                            className="fil2 str1"
                            d="M16418.24 19177.42c-1.85 16.66-9.25 34.35-22.22 48.14-12.96 13.78-31.47 23.66-51.02 30.45-19.54 6.78-40.11 10.49-61.31 10.08-21.19-.42-42.99-4.94-63.98-12.55-20.98-7.62-41.14-18.31-55.13-34.36-13.99-16.05-21.81-37.44-21.61-55.96.21-18.52 8.44-34.15 22.02-46.91 13.58-12.75 32.5-22.63 52.66-29.01 20.17-6.37 41.56-9.25 61.31-10.08 19.75-.82 37.86.42 55.14 5.15s33.74 12.96 46.7 21.39c12.96 8.44 22.42 17.08 29.01 29.22 6.58 12.14 10.29 27.77 8.43 44.44z"
                            id="path142"
                        />
                        <path
                            className="fil2 str1"
                            d="M16100.59 19041.43c-1.85 10.69-9.67 24.68-21.81 35.18-12.13 10.49-28.59 17.48-47.52 21.19-18.93 3.7-40.32 4.11-60.07 1.85-19.75-2.26-37.86-7.2-54.11-15.84s-30.65-20.99-38.27-29.83c-7.61-8.85-8.43-14.2-5.14-21.4 3.29-7.2 10.7-16.25 25.92-25.72 15.23-9.46 38.27-19.34 59.66-23.86 21.4-4.53 41.15-3.7 58.23-.21 17.07 3.5 31.47 9.67 43.82 15.64 12.34 5.96 22.63 11.73 29.83 18.31 7.2 6.58 11.31 13.99 9.46 24.69z"
                            id="path144"
                        />
                        <path
                            className="fil2 str1"
                            d="M15977.77 19018.18c9.88-1.03 23.04-1.03 32.51.82 9.46 1.85 15.22 5.56 15.63 9.88.41 4.32-4.52 9.25-13.99 11.93-9.46 2.67-23.45 3.08-34.56 1.85-11.11-1.23-19.34-4.11-22.63-7.61-3.29-3.5-1.65-7.61 2.47-10.7 4.11-3.09 10.69-5.14 20.57-6.17z"
                            id="path146"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}