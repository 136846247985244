import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components";
import VirtualizedSelectionBox from "./VirtualizedSelectionBox/VirtualizedSelectionBox";


export default function GeneSelectionBox({ className, genes, setSelectedGene}) {

    function handleSelection(value) {
        setSelectedGene(value)
    }

    return (
      <div className={className}>
        <StyledContainer>
          <VirtualizedSelectionBox label={"Select gene:"} options={genes} handleSelection={handleSelection}/>
        </StyledContainer>
      </div>
    );
}

const StyledContainer = styled.div`
    margin-bottom: 50px;
    margin-top: 25px;

    @media(max-width:600px){
        margin: 0px;
    }
`