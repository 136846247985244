import { useEffect, useState, useRef } from "react";
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import { Chart as Chart2 } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { valueToColor } from "../../helpers/colors";
import styled from 'styled-components';
import brain from "../../images/organs/Brain_icon.svg"
import blood from "../../images/organs/Blood_icon.svg"
import colon from "../../images/organs/Colon_icon.svg"
import heart from "../../images/organs/Heart_icon.svg"
import kidney from "../../images/organs/Kidney_icon.svg"
import liver from "../../images/organs/Liver_icon.svg"
import lungs from "../../images/organs/Lungs_icon.svg"
import lymphnode from "../../images/organs/Lymphnode_icon.svg"
import nasopharynx from "../../images/organs/Nasopharynx_icon.svg"
import testis from "../../images/organs/Testis_icon.svg"
import trachea from "../../images/organs/Trachea_icon.svg"
import { initialOptions } from "./initialOptions";

export default function BarChart({ className, selectedData, isMobile }) {

  Chart.register(annotationPlugin);

  const [data, setData] = useState(null)
  const [options, setOptions] = useState(initialOptions)
  
  const chartReference = useRef(null);

  useEffect(() => {
    let x = [];
    let y = [];
    let color = [];
    let annotationsToLoad = [];
    selectedData?.data.forEach((element) => {
      x.push(element.value);
      y.push(element.name);
      color.push(valueToColor(element.value));
      if(element.value !== 0){
        annotationsToLoad.push(element)
      }
    });
    const chartData = {
      labels: y,
      datasets: [
        {
          label: "log2FC",
          data: x,
          backgroundColor: color,
          borderColor: color,
          borderRadius: 10,
          borderWidth: 1,
        },
      ],
    };
    let plugins
    const baseOptions = {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          min: -8.0,
          max: 8.0,
          grid: {
            display: false,
          },
        },
        y: {
          position: "center",
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      /*onClick: (e) => {
         
      }*/
    };

    if (isMobile && annotationsToLoad.length > 0) {
      let annotations;
        selectedData?.data.forEach((element) => {
          const propertyName = `annotation${element.name}`
          annotations = {
            ...annotations, [propertyName]: organAnnotation(element.name, element.value)
          }
        })
        plugins = {
          legend: {
            display: false,
          },
          annotation: {
            animations: {
              numbers: {
                properties: ['x'],
                type: 'number'
              },
            },
            annotations: annotations
          }
        }
      } else {
        plugins = {
          legend: {
            display: false,
          },
          annotation: {
            animations: {
              numbers: {
                properties: ['x'],
                type: 'number'
              },
            }
          }
        }
      }
      setOptions({ ...baseOptions, plugins });
      setData(chartData);
      const chart = chartReference.current;
      if (chart) {
        chart.update()
      }

  }, [selectedData, isMobile]);

  function organAnnotation(organ, value) {

    let image;

    switch (organ) {
        case "Brain":
            image=getImage(brain)
            break
        case "Blood":
            image=getImage(blood)
            break
        case "Colon":
            image=getImage(colon)
            break
        case "Heart":
            image=getImage(heart)
            break
        case "Kidney":
            image=getImage(kidney)
            break
        case "Liver":
            image=getImage(liver)
            break
        case "Lungs":
            image=getImage(lungs)
            break
        case "Lymphnode":
            image=getImage(lymphnode)
            break
        case "Nasopharynx":
            image=getImage(nasopharynx)
            break
        case "Testis":
            image=getImage(testis)
            break
        case "Trachea":
            image=getImage(trachea)
            break
    }

    function getImage(organ) {
        const img = new Image();
        img.src = organ;
        return img;
    }

    return ({
        type: 'label',
        drawTime: 'beforeDatasetsDraw',
        content: image,
        display: value!==0,
        position: {
            x: 'center',
            y: 'center'
          },
        xValue: value>0?-1.5:1.5,
        yValue: organ,
        callout: {
            display: true,
            position: 'left'
        },
    })
}


  return (
    <div className={className}>
      <ContainerStyle>
        {selectedData ? (
          data ? (
            options ? (
              <Bar
                ref={chartReference}
                data={data}
                options={options}
              />
            ) : null
          ) : null
        ) : null}
      </ContainerStyle>
    </div>
  );
}

export const ContainerStyle = styled.div`
    max-width: 300px;
    height: 600px;
`;