import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function Lungs({ children, text, fill, stroke }) {

    const [style, setStyle] = useState({
        stroke: "#bdbfc1",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256, 
            fill: fill
        })
    }, [fill, stroke])

    const tooltipText = <p>Lungs<br />{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg176"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs20">
                    <style type="text/css" id="style18">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style>
                </defs>
                <g id="Layer_x0020_1" transform="translate(-15456.04 -11744.14)">
                    <g id="_2033384792448">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={12403.24}
                            r={623.82}
                            id="circle146"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M16076.46 12037.24c-.48 23.14-.95 46.28-1.42 67.77-.47 21.48-.95 41.32-4.25 60.21-3.31 18.89-9.45 36.83-17.47 50.29-8.03 13.46-17.95 22.44-29.76 30.23-11.8 7.79-25.5 14.4-39.19 21.01"
                            id="path148"
                        />
                        <path
                            className="fil2 str1"
                            d="M16150.13 12038.66c.47 41.56.94 83.11 2.59 109.09 1.66 25.97 4.49 36.36 8.5 46.04 4.02 9.68 9.21 18.65 16.06 27.39 6.85 8.74 15.35 17.24 25.5 24.79 10.15 7.56 21.96 14.17 33.77 20.78"
                            id="path150"
                        />
                        <path
                            className="fil2 str1"
                            d="M16053.32 12215.51c-3.63-12.67-7.25-25.34-11.06-35.93-3.82-10.58-7.83-19.08-16.81-24.28-8.97-5.19-22.9-7.08-38.13-4.25-15.23 2.84-31.76 10.39-49.47 21.02-17.71 10.62-36.6 24.32-54.78 40.14s-35.65 33.76-50.77 56.67c-15.11 22.9-27.86 50.76-37.54 75.32-9.68 24.56-16.29 45.81-20.78 69.42-4.48 23.61-6.85 49.59-7.79 77.21-.94 27.63-.47 56.91.47 78.16.95 21.25 2.36 34.47 4.49 44.39 2.12 9.92 4.96 16.53 9.91 22.91 4.96 6.37 12.05 12.51 20.55 14.87 8.5 2.36 18.42.95 26.68.24 8.26-.71 14.88-.71 36.6 5.9s58.56 19.83 86.42 26.45c27.86 6.61 46.75 6.61 60.45 2.59 13.69-4.01 22.19-12.04 30.22-31.4 8.03-19.36 15.59-50.06 21.02-82.17 5.43-32.12 8.73-65.64 10.39-99.88 1.65-34.24 1.65-69.19 1.65-104.13"
                            id="path152"
                        />
                        <path
                            className="fil2 str1"
                            d="M16193.34 12152.7c9.68-3.06 19.36-6.14 31.99-5.54 12.63.59 28.22 4.84 47.34 14.28 19.13 9.45 41.8 24.09 62.81 41.8 21.02 17.7 40.38 38.48 55.73 61.15 15.35 22.67 26.68 47.23 36.12 70.84 9.45 23.61 17.01 46.28 22.67 73.67 5.67 27.39 9.45 59.5 11.34 88.07 1.89 28.57 1.89 53.6 1.18 73.67s-2.13 35.19-6.38 47.46c-4.25 12.28-11.33 21.73-17.94 27.39-6.62 5.67-12.75 7.56-22.91 8.27-10.15.71-24.32.23-38.01 2.12-13.7 1.89-26.92 6.14-44.16 12.28-17.24 6.14-38.49 14.17-57.85 18.42-19.36 4.25-36.83 4.72-51.95.94-15.11-3.77-27.86-11.8-38.48-29.28-10.63-17.47-19.13-44.39-25.27-77.92-6.14-33.53-9.92-73.67-11.57-106.25-1.65-32.59-1.18-57.62.24-81.94 1.41-24.32 3.78-47.93 6.14-71.54"
                            id="path154"
                        />
                        <path
                            className="fil2 str1"
                            d="M16203.96 12341.84c-10.86-8.03-21.72-16.06-29.99-19.6-8.26-3.54-13.93-2.6-21.72-4.01-7.79-1.42-17.71-5.2-28.33-7.09-10.63-1.89-21.96-1.89-34.71.24s-26.92 6.37-38.49 11.33-20.54 10.63-29.52 16.3"
                            id="path156"
                        />
                        <path
                            className="fil2 str1"
                            d="M16071.24 12505.98c-10.07-3.24-20.14-6.48-31.31-11.4-11.17-4.93-23.45-11.54-33.84-23.34-10.39-11.81-18.89-28.81-27.39-45.81"
                            id="path158"
                        />
                        <path
                            className="fil2 str1"
                            d="M15999.81 12463.2c-8.93 2.99-17.85 5.99-26.09 9.37-8.24 3.39-15.8 7.17-23.35 10.94"
                            id="path160"
                        />
                        <path
                            className="fil2 str1"
                            d="M16150.03 12504.54c9.47-1.34 18.95-2.68 30.07-7.37 11.11-4.68 23.86-12.71 35.2-25.46 11.33-12.75 21.25-30.23 31.16-47.7"
                            id="path162"
                        />
                        <path
                            className="fil2 str1"
                            d="M16209.5 12477.72c9.49-2.32 18.98-4.64 29.62-3.67 10.65.96 22.46 5.21 34.26 9.46"
                            id="path164"
                        />
                        <path
                            className="fil2 str1"
                            d="M16462.52 12570.64c-12.99-.71-25.98-1.41-40.73-6.25-14.76-4.85-31.29-13.82-47.82-22.79"
                            id="path166"
                        />
                        <path
                            className="fil2 str1"
                            d="M15766.66 12568.99c12.6-.63 25.19-1.26 39.04-5.59 13.85-4.33 28.97-12.36 44.08-20.38"
                            id="path168"
                        />
                        <path
                            className="fil2 str1"
                            d="M16421.79 12564.39c-5.55 6.1-11.1 12.2-19.31 17.84-8.2 5.65-19.06 10.84-29.93 16.04"
                            id="path170"
                        />
                        <path
                            className="fil2 str1"
                            d="M15805.7 12563.4c5.72 6.9 11.44 13.8 19.97 19.61 8.52 5.81 19.86 10.54 31.19 15.26"
                            id="path172"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}