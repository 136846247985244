import { useEffect, useState } from "react"
import * as d3 from "d3"
import { valueToColor } from "../../../helpers/colors";
import styled from 'styled-components';
import Brain from "../Organs/Brain";
import Nasopharynx from "../Organs/Nasopharynx";
import Trachea from "../Organs/Trachea";
import Heart from "../Organs/Heart";
import Lungs from "../Organs/Lungs";
import Liver from "../Organs/Liver";
import Kidney from "../Organs/Kidney";
import Colon from "../Organs/Colon";
import Testis from "../Organs/Testis";
import Blood from "../Organs/Blood";
import Lymphnode from "../Organs/Lymphnode";

export default function OrgansHeatmap2({ className, selectedData }) {
    const [heatmapData, setHeatmapData] = useState(null)

    useEffect(() => {
        if (selectedData) {
            const properties = selectedData.data.map(e => {
                return {
                    name: e.name,
                    value: e.value,
                    fill: valueToColor(e.value),
                    stroke: e.value? valueToColor(e.value, 240, 0, 0.6) : "hsl(210, 3%, 75%)"
                }
            })
            setHeatmapData([...properties])
        }
    }, [selectedData])

    return (
        <StyledContainer className={className}>
            <div className={"organs-heatmap-container"}>
                <Brain fill={heatmapData ? heatmapData[0].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[0].stroke : "hsl(210, 3%, 75%)"}/>
                <Nasopharynx fill={heatmapData ? heatmapData[1].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[1].stroke : "hsl(210, 3%, 75%)"} />
                <Trachea fill={heatmapData ? heatmapData[2].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[2].stroke : "hsl(210, 3%, 75%)"} />
                <Heart fill={heatmapData ? heatmapData[3].fill : '#FFFFFF' } stroke={heatmapData ? heatmapData[3].stroke : "hsl(210, 3%, 75%)"}/>
                <Lungs fill={heatmapData ? heatmapData[4].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[4].stroke : "hsl(210, 3%, 75%)"}/>
                <Liver fill={heatmapData ? heatmapData[5].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[5].stroke : "hsl(210, 3%, 75%)"}/>
                <Kidney fill={heatmapData ? heatmapData[6].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[6].stroke : "hsl(210, 3%, 75%)"}/>
                <Colon fill={heatmapData ? heatmapData[7].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[7].stroke : "hsl(210, 3%, 75%)"}/>
                <Testis fill={heatmapData ? heatmapData[8].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[8].stroke : "hsl(210, 3%, 75%)"}/>
                <Blood fill={heatmapData ? heatmapData[9].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[9].stroke : "hsl(210, 3%, 75%)"}/>
                <Lymphnode fill={heatmapData ? heatmapData[10].fill : '#FFFFFF'} stroke={heatmapData ? heatmapData[10].stroke : "hsl(210, 3%, 75%)"}/>
            </div>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    margin-right: -50%;

    .organs-heatmap-container{
        box-sizing: border-box;
        padding-top: 2px;
        padding-bottom: 35px;
        height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    @media (max-width:600px){
        margin:0px;

        #Organs{
        height: 96.5%;
        margin-right: 10px;
        }

        .organs-heatmap-container{
        justify-content: flex-start;
    }
    }
`