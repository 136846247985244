import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function Testis({ children, text, fill, stroke }) {

    const [style, setStyle] = useState({
        stroke: "#bdbfc1",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256, 
            fill: fill
        })
    }, [fill, stroke])

    const tooltipText = <p>Testis<br />{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg923"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs783">
                    <style type="text/css" id="style781">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style>
                </defs>
                <g id="Layer_x0020_1" transform="translate(-15456.04 -17240.861)">
                    <g id="_2031577789728">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={17899.961}
                            r={623.82}
                            id="circle909"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M15971.35 17686.47c-.51 11.74-1.02 23.48-2.17 35.99-1.15 12.51-2.94 25.78-7.02 39.31-4.08 13.53-10.46 27.31-16.97 41.22-6.51 13.91-13.15 27.95-18.89 43.13-5.74 15.19-10.59 31.53-13.66 47.1-3.06 15.57-4.34 30.37-4.72 47.47-.38 17.1.13 36.5 3.06 54.49 2.94 18 8.3 34.59 15.57 50.03 7.28 15.44 16.47 29.74 27.83 41.22 11.35 11.49 24.88 20.17 37.26 24.76 12.38 4.6 23.61 5.11 34.07 4.09 10.47-1.03 20.17-3.58 28.72-7.92 8.55-4.34 15.95-10.46 23.48-18.76 7.53-8.29 15.19-18.76 21.95-24.37 6.77-5.62 12.64-6.38 17.36-5.75 4.72.64 8.29 2.68 13.27 8.3 4.98 5.62 11.36 14.8 19.4 22.21 8.04 7.4 17.74 13.01 26.67 17.1 8.94 4.08 17.1 6.63 27.95 7.4 10.85.76 24.38-.26 35.1-3.83 10.72-3.57 18.63-9.7 27.31-18.25s18.12-19.52 25.78-34.2c7.65-14.68 13.52-33.05 17.86-51.56 4.34-18.51 7.15-37.14 8.3-54.11 1.15-16.97.64-32.29-2.04-48.24-2.68-15.95-7.53-32.54-12.51-47.48-4.98-14.93-10.08-28.2-16.34-41.09-6.25-12.89-13.65-25.4-19.01-37.65-5.36-12.25-8.68-24.25-10.59-35.73-1.92-11.49-2.43-22.46-2.56-31.91-.12-9.44.13-17.35.39-25.27"
                            id="path911"
                        />
                        <path
                            className="fil2 str1"
                            d="M16014.1 17846.12c-9.57 1.15-21.57 4.98-30.88 11.36-9.32 6.38-15.95 15.32-21.83 25.53-5.87 10.21-10.97 21.69-14.42 36.24-3.44 14.55-5.23 32.16-5.1 47.86.13 15.7 2.17 29.48 6.12 42.5 3.96 13.01 9.83 25.27 16.47 35.86 6.63 10.59 14.04 19.52 21.56 25.78 7.53 6.25 15.19 9.82 24.51 10.97 9.31 1.15 20.29-.13 29.1-5.1 8.8-4.98 15.44-13.66 22.84-25.02 7.4-11.35 15.57-25.39 20.67-40.45 5.11-15.06 7.15-31.14 6.51-48.11-.64-16.98-3.95-34.84-9.19-51.05-5.23-16.21-12.37-30.76-19.78-41.22-7.4-10.47-15.06-16.85-22.46-20.81-7.4-3.95-14.55-5.48-24.12-4.34z"
                            id="path913"
                        />
                        <path
                            className="fil2 str1"
                            d="M16208.21 17846.63c-6.63.64-16.84 2.17-26.16 8.17s-17.74 16.47-25.27 29.48c-7.53 13.02-14.16 28.59-18.12 42.12-3.96 13.53-5.23 25.01-4.85 37.77.38 12.77 2.42 26.8 6.13 40.08 3.7 13.27 9.06 25.78 15.56 37.26 6.51 11.49 14.17 21.95 23.61 29.23 9.45 7.27 20.68 11.35 32.42 10.72 11.74-.64 23.99-6 34.07-15.06 10.09-9.06 18-21.83 24-36.12s10.08-30.12 12.12-46.2c2.04-16.08 2.04-32.41-.77-48.24-2.8-15.82-8.42-31.14-15.56-44.41-7.15-13.27-15.83-24.5-23.49-31.65-7.65-7.15-14.29-10.21-19.14-11.87-4.85-1.66-7.91-1.91-14.55-1.28z"
                            id="path915"
                        />
                        <path
                            className="fil2 str1"
                            d="M16208.72 17887.09c1.79 8.17 3.58 16.34 3.32 23.23-.25 6.89-2.55 12.5-5.23 17.35-2.68 4.85-5.74 8.94-5.53 14.85.21 5.91 3.69 13.66 5.35 20.55 1.66 6.89 1.49 12.93 1.66 20.16.18 7.23.69 15.66.94 21.02.26 5.36.26 7.65-1.02 10.59-1.27 2.93-3.83 6.51-4.08 10.97-.26 4.47 1.78 9.83 5.61 12.26 3.83 2.42 9.45 1.91 12.89-1.28 3.45-3.19 4.73-9.06 4.09-14.17-.64-5.1-3.19-9.44-6.51-10.72-3.32-1.27-7.4.51-11.49 2.3"
                            id="path917"
                        />
                        <path
                            className="fil2 str1"
                            d="M16018.82 18026.45c1.02-6.63 2.05-13.27.26-20.8-1.79-7.53-6.38-15.95-8.55-23.48-2.17-7.53-1.92-14.17-.39-19.78 1.54-5.62 4.34-10.21 4.98-16.59.64-6.39-.89-14.55-.89-21.7 0-7.15 1.53-13.27 3.06-16.46 1.53-3.19 3.07-3.45 5.36-3.45 2.3 0 5.36.26 7.66-2.04s3.83-7.15 3.06-11.23c-.76-4.08-3.83-7.4-7.4-8.55-3.57-1.15-7.66-.13-10.34 1.91-2.68 2.05-3.95 5.11-3.95 8.43 0 3.31 1.27 6.89 2.55 10.46"
                            id="path919"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}