import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function Lymphnode({ children, text, fill, stroke }) {

    const [style, setStyle] = useState({
        stroke: "#bdbfc1",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256, 
            fill: fill
        })
    }, [fill, stroke])

    const tooltipText = <p>Lymphnode<br />{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg168"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs20">
                    <style type="text/css" id="style18">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style>
                </defs>
                <g id="Layer_x0020_1" transform="translate(-15456.04 -19989.291)">
                    <g id="_2031575262960">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={20648.391}
                            r={623.82}
                            id="circle146"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M15839.64 20369.88c14.29 3.39 28.57 6.77 43.6 12.41 15.03 5.63 30.82 13.53 45.48 22.74 14.66 9.2 28.19 19.73 41.72 30.25"
                            id="path148"
                        />
                        <path
                            className="fil2 str1"
                            d="M16026.81 20414.99c7.9.37 15.79.75 24.06 3.75 8.27 3.01 16.91 8.65 24.43 15.6 7.51 6.95 13.9 15.22 18.6 26.12 4.7 10.9 7.71 24.43 12.97 33.26 5.26 8.84 12.78 12.97 25.56 17.11 12.77 4.13 30.81 8.26 46.98 11.83 16.16 3.58 30.44 6.58 40.77 2.64 10.34-3.95 16.73-14.85 24.62-23.87 7.9-9.02 17.29-16.16 28-22.18 10.71-6.01 22.74-10.89 36.08-12.77 13.35-1.88 28-.76 40.78 4.51 12.78 5.26 23.68 14.65 31.76 24.99 8.08 10.34 13.34 21.61 16.16 32.89 2.82 11.27 3.2 22.55 2.26 32.69-.94 10.15-3.2 19.17-5.45 28.19"
                            id="path150"
                        />
                        <path
                            className="fil2 str1"
                            d="M15837.39 20431.9c13.91 4.88 27.81 9.77 40.21 15.41 12.41 5.64 23.31 12.02 31.39 17.29 8.08 5.26 13.34 9.39 13.9 17.1.57 7.7-3.57 18.98-5.64 30.44-2.06 11.46-2.06 23.12.19 34.2 2.26 11.09 6.77 21.61 11.09 30.07s8.46 14.85 10.34 23.87c1.88 9.02 1.5 20.67.94 31.19-.57 10.52-1.32 19.92.37 31.01s5.83 23.86 10.52 35.52c4.7 11.65 9.96 22.17 16.54 33.63 6.58 11.47 14.47 23.87 17.67 32.51 3.19 8.65 1.69 13.53-.19 18.42-1.88 4.89-4.14 9.77-10.34 12.21-6.2 2.45-16.35 2.45-28.19 3.76-11.83 1.32-25.36 3.95-38.89 8.08-13.53 4.14-27.06 9.78-38.15 15.98-11.09 6.2-19.73 12.96-28.38 19.73"
                            id="path152"
                        />
                        <path
                            className="fil2 str1"
                            d="M15839.64 20923.5c9.4-9.77 18.8-19.54 27.82-27.81s17.66-15.03 29.12-20.86c11.47-5.83 25.75-10.71 38.34-14.09 12.59-3.39 23.49-5.27 29.32-3.01 5.82 2.25 6.57 8.64 9.39 15.78 2.82 7.15 7.71 15.04 14.66 22.55 6.95 7.52 15.97 14.66 25.75 19.55 9.77 4.88 20.29 7.52 32.69 7.7 12.41.19 26.69-2.06 38.34-6.76 11.65-4.7 20.67-11.84 27.06-17.29 6.39-5.45 10.15-9.21 15.41-9.02 5.26.19 12.03 4.32 18.23 8.64 6.2 4.33 11.84 8.84 16.72 13.53 4.89 4.7 9.02 9.59 13.16 14.47"
                            id="path154"
                        />
                        <path
                            className="fil2 str1"
                            d="M16244.43 20923.5c-6.02-10.15-12.03-20.29-19.55-30.44-7.51-10.15-16.53-20.3-24.24-27.82-7.7-7.51-14.09-12.4-21.99-16.91-7.89-4.51-17.28-8.64-21.04-15.41-3.76-6.76-1.88-16.16-1.51-27.06.38-10.9-.75-23.3-2.44-31-1.69-7.71-3.95-10.72 3.57-19.92 7.52-9.21 24.81-24.62 46.6-40.03 21.8-15.41 48.11-30.82 64.27-37.77 16.17-6.96 22.18-5.45 28.57-5.83 6.39-.38 13.15-2.63 19.17.38 6.01 3 11.27 11.27 17.66 19.16 6.39 7.9 13.91 15.41 22.18 22.37 8.26 6.95 17.29 13.34 25.55 17.29 8.27 3.94 15.79 5.45 23.31 6.95"
                            id="path156"
                        />
                        <path
                            className="fil2 str1"
                            d="M16365.07 20623.58c1.51 6.76 3.01 13.53 5.45 19.92 2.45 6.39 5.83 12.4 11.28 18.6 5.45 6.2 12.96 12.59 20.48 18.98"
                            id="path158"
                        />
                        <path
                            className="fil2 str1"
                            d="M16101.23 20727.31c11.28-11.27 22.55-22.55 35.71-34.39 13.15-11.84 28.18-24.24 43.03-33.82 14.85-9.59 29.5-16.35 37.21-20.68 7.7-4.32 8.46-6.2 7.33-11.46-1.13-5.26-4.14-13.91-6.2-21.42-2.07-7.52-3.2-13.91-5.64-17.1-2.45-3.2-6.2-3.2-16.54-3.57-10.33-.38-27.25-1.13-42.09-3.95-14.85-2.82-27.63-7.7-37.96-12.97-10.34-5.26-18.23-10.9-23.49-12.78-5.27-1.87-7.9 0-10.72 5.27-2.81 5.26-5.82 13.9-11.65 21.61-5.82 7.7-14.47 14.47-24.99 20.48-10.52 6.02-22.93 11.28-31.38 14.09-8.46 2.82-12.97 3.2-15.6 4.52-2.63 1.31-3.38 3.57-2.63 12.96.75 9.4 3.01 25.94 6.76 39.84 3.76 13.91 9.02 25.18 14.29 33.83 5.26 8.64 10.52 14.65 15.78 20.67"
                            id="path160"
                        />
                        <path
                            className="fil2 str1"
                            d="M15959.16 20516.46c0-5.64 0-11.27 2.07-16.16 2.07-4.88 6.2-9.02 10.33-13.15"
                            id="path162"
                        />
                        <path
                            className="fil2 str1"
                            d="M16002.57 20456.14c-3.19-2.07-8.08.94-9.39 4.89-1.32 3.94.93 8.83 4.13 10.9 3.19 2.06 7.33 1.31 8.64-2.64 1.32-3.94-.18-11.08-3.38-13.15z"
                            id="path164"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}