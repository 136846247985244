import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';

export default function Heart({ children, text, fill, stroke }) {

    const [style, setStyle] = useState({
        stroke: "hsl(210, 3%, 75%)",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256, 
            fill: fill
        })
    }, [fill, stroke])

    const tooltipText = <p>Heart<br />{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg428"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs284">
                    {/* <style type="text/css" id="style282">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style> */}
                </defs>
                <g id="Layer_x0020_1" transform="translate(-15456.04 -10369.96)">
                    <g id="_2033605198240">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={11029.06}
                            r={623.82}
                            id="circle410"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M16326.7 10897.61c4.94 11.79 9.88 23.58 13.69 42.02 3.8 18.45 6.46 43.55 5.7 68.27-.76 24.71-4.94 49.05-11.79 76.44-6.84 27.38-16.35 57.8-29.47 85.18-13.12 27.38-29.85 51.72-48.87 70.74-19.01 19.01-40.31 32.7-55.52 41.64-15.21 8.94-24.34 13.12-36.13 15.02-11.79 1.9-26.24 1.52-44.69-4.37-18.44-5.9-40.88-17.3-61.23-32.71-20.34-15.4-38.59-34.79-54.57-58.37-15.97-23.58-29.66-51.34-39.55-79.1-9.89-27.77-15.97-55.53-18.82-80.44-2.86-24.91-2.47-46.97 2.28-68.26 4.75-21.3 13.88-41.83 25.29-61.61 11.41-19.78 25.1-38.79 39.17-54.38 14.07-15.6 28.52-27.76 45.25-38.98 16.74-11.22 35.75-21.49 54.58-30.05 18.82-8.55 37.46-15.4 56.28-20.72 18.83-5.33 37.84-9.13 54-11.6 16.17-2.47 29.48-3.61 41.08-4.38 11.6-.76 21.48-1.14 29.85 3.24 8.37 4.37 15.21 13.5 18.82 22.24 3.62 8.75 4 17.12 1.91 25.67-2.1 8.56-6.66 17.31-14.83 23.77-8.18 6.47-19.97 10.65-30.43 14.45-10.46 3.81-19.58 7.23-29.66 12.17-10.08 4.95-21.11 11.41-29.48 17.69-8.36 6.27-14.07 12.36-19.77 18.44"
                            id="path412"
                        />
                        <path
                            className="fil2 str1"
                            d="M16014.34 11215.98c-4.26-17.52-8.53-35.03-10.86-50.63-2.32-15.6-2.7-29.29 1.29-42.41 4-13.12 12.36-25.67 24.34-39.94 11.98-14.26 27.57-30.23 40.88-43.92 13.31-13.69 24.34-25.1 33.09-38.41 8.75-13.31 15.21-28.52 18.45-42.78 3.23-14.27 3.23-27.58-1.15-40.7-4.37-13.12-13.12-26.05-19.39-34.03-6.28-7.99-10.08-11.03-13.88-14.07"
                            id="path414"
                        />
                        <path
                            className="fil2 str1"
                            d="M16270.79 10772.11c.76 5.7 1.52 11.41 4.57 15.97 3.04 4.57 8.36 7.99 13.69 11.41"
                            id="path416"
                        />
                        <path
                            className="fil2 str1"
                            d="M16165.83 11004.85c7.99 13.69 15.97 27.38 25.1 39.17 9.13 11.79 19.4 21.68 27 26.62 7.61 4.95 12.55 4.95 20.16 2.29 7.6-2.67 17.87-7.99 28.71-11.6 10.84-3.62 22.25-5.52 33.66-7.42"
                            id="path418"
                        />
                        <path
                            className="fil2 str1"
                            d="M16168.11 11124.65c7.99-8.37 15.97-16.74 25.9-25.08 9.93-8.34 21.81-16.65 27.74-20.81l5.94-4.15"
                            id="path420"
                        />
                        <path
                            className="fil2 str1"
                            d="M16252.39 11066.98c-4.77 1.98-9.54 3.96-11.98 11.29-2.45 7.33-2.58 20.01-10.44 15.19-7.86-4.81-23.45-27.13-33.15-35.68-9.69-8.56-13.5-3.36-19.2-.76-5.71 2.6-13.31 2.6-19.78 1.83-6.46-.76-11.79-2.28-17.11-3.8"
                            id="path422"
                        />
                        <path
                            className="fil2 str1"
                            d="M16052.88 10761.84c-8.36-9.12-16.73-18.25-25.86-22.63-9.13-4.37-19.01-3.99-26.81 0-7.8 4-13.5 11.6-17.11 20.54-3.62 8.94-5.14 19.21-5.14 31.19 0 11.97 1.52 25.66 3.04 37.26 1.53 11.6 3.05 21.11 4.75 30.04 1.7 8.92 3.58 17.27 5.45 25.62"
                            id="path424"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}