import styled from "styled-components"
import { ReactComponent as MaleBody } from "../../images/homo_sapiens.male.opt4.svg"
import { ReactComponent as FemaleBody } from "../../images/homo_sapiens.female.opt4.svg"
import * as d3 from "d3"
import { valueToColor } from "../../helpers/colors";
import { useEffect, useState } from "react";
import { IoMdFemale, IoMdInformationCircle, IoMdMale } from "react-icons/io"
import ToggleButton from "../ToggleButton/ToggleButton";

export default function Anatogram({ className, selectedData, showBiologicalSexToggleButton }) {

    const [showFemaleBody, setShowFemaleBody] = useState(false)

    useEffect(() => {
        if (selectedData) {
            const structures = d3.selectAll(`.anatomogram-model g`)._groups[0]
            structures.forEach(element => {
                if (element.id !== "Body") {
                    element.style.fill = "none"
                }
            })
            selectedData.data.forEach(element => {
                const structure = d3.select(`.anatomogram-model #${element.name}`)._groups[0][0]
                if (structure) {
                    structure.style.fill = valueToColor(element.value)
                }
            });
        }
    }, [selectedData, showFemaleBody])

    return (
        <>
            <div className={className}>
                <StyledContainer>
                    {showFemaleBody ? <FemaleBody /> : <MaleBody />}
                    {showBiologicalSexToggleButton ? <BiologicalSexToggleButton state={showFemaleBody} setState={setShowFemaleBody} /> : <></>}
                    <InfoButton/>
                </StyledContainer>
            </div>
        </>
    )
}

function BiologicalSexToggleButton(state, setState) {
    return(
        <ToggleButton setState={setState}>
        {state ? <IoMdMale /> : <IoMdFemale />}
    </ToggleButton>
    )
}

function InfoButton(){
  return(
    <StyledInfoButton href="https://www.ebi.ac.uk/gxa/licence.html">
      <IoMdInformationCircle/>
    </StyledInfoButton>
  )
}

const StyledInfoButton = styled.a`
  color: rgb(59, 68, 75);
`

const StyledContainer = styled.div`
    padding-top: 10%;

    @media(max-width:600px){
        display: flex;
        flex-direction: column;
        padding-top: unset;
        height: 600px;
    }
`