import styled from "styled-components"

export default function Logo({ src, className }) {
    return (
        <div className={className}>
            <StyledContainer>
                <img src={src} alt={"CSBL logo"}/>
            </StyledContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    img{
        height: 100px;
    }

    @media(max-width: 600px) {
        img{
            height: 15vw;
        }
    }
`