import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function Kidney({ children, text, fill, stroke }) {

    const [style, setStyle] = useState({
        stroke: "#bdbfc1",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256, 
            fill: fill
        })
    }, [fill, stroke])

    const tooltipText = <p>Kidney<br />{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg683"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs547">
                    <style type="text/css" id="style545">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style>
                </defs>
                <g id="Layer_x0020_1" transform="translate(-15456.04 -14492.49)">
                    <g id="_2033369657680">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={15151.59}
                            r={623.82}
                            id="circle673"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M16074.94 15026.66c-2.14-16.89-14.03-39.25-20.93-58.52-6.9-19.27-8.8-35.45-7.38-50.91 1.43-15.47 6.19-30.22 16.66-45.44 10.46-15.23 26.64-30.93 43.29-39.25 16.66-8.33 33.78-9.28 55.67-5.95 21.89 3.33 48.53 10.94 68.99 20.46 20.46 9.51 34.73 20.93 49.96 37.35 15.22 16.41 31.4 37.82 44.96 63.51 13.56 25.7 24.5 55.67 30.45 87.07 5.95 31.41 6.9 64.24 7.14 92.07.23 27.83-.24 50.67-5.24 76.84-4.99 26.17-14.51 55.67-26.17 82.79-11.65 27.12-25.45 51.86-45.67 70.65-20.22 18.79-46.87 31.64-74.94 36.4-28.07 4.76-57.57 1.43-80.17-6.66s-38.3-20.94-48.77-36.87c-10.46-15.94-15.7-34.98-17.6-55.43-1.9-20.46-.48-42.35 3.57-58.53 4.04-16.17 10.7-26.64 11.42-39.01.71-12.37-4.52-26.65-8.33-42.35-3.81-15.7-6.19-32.83-7.61-47.81-1.43-14.99-1.91-27.84 1.66-39.97 3.57-12.13 11.18-23.55 9.04-40.44z"
                            id="path675"
                        />
                        <path
                            className="fil2 str1"
                            d="M16065.73 14996.99c-16.04 4.1-32.08 8.2-48.66 17.63-16.59 9.42-33.72 24.17-47.99 44.63-14.27 20.46-25.69 46.63-32.35 72.8-6.67 26.16-8.57 52.33-7.14 81.12 1.43 28.78 6.18 60.18 10.23 86.83 4.04 26.64 7.37 48.53 9.75 76.13 2.38 27.59 3.81 60.9 5.24 94.2"
                            id="path677"
                        />
                        <path
                            className="fil2 str1"
                            d="M16075.11 15165.51c-11.89 6.55-23.79 13.1-32.83 28.88-9.04 15.79-15.22 40.8-16.17 70.88-.96 30.07 3.33 65.21 6.42 100.35s4.99 70.28 6.9 105.42"
                            id="path679"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}