import { IconButton } from "@mui/material";


export default function ToggleButton({ setState, children }) {

    function handleClick() {
        setState(s => !s)
    }


    return (
        <>
            <IconButton
                aria-label="change biological sex for the model"
                sx={{
                    position: 'absolute',
                    top: '5%',
                    left: '5%',
                    boxShadow:"1px 1px #888888",
                }}
                onClick={handleClick}>
                {children}
            </IconButton>
        </>
    )
}