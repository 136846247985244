import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function Brain({ children, text, fill, stroke }) {

    const [style, setStyle] = useState({
        stroke: "#bdbfc1",
        strokeWidth: 50,
        strokeMiterlimit: 22.9256,
    })

    useEffect(() => {
        setStyle({
            stroke: stroke,
            strokeWidth: 50,
            strokeMiterlimit: 22.9256, 
            fill: fill
        })
    }, [fill, stroke])

    const tooltipText = <p>Brain<br/>{text}</p>

    return (
        <Tooltip title={tooltipText} arrow placement="left" PopperProps={{sx:{
            "& .MuiTooltip-tooltip": {
                fontSize: 12,
                fontWeight: "bold"
              }
        }}}>
            <svg
                xmlSpace="preserve"
                width={49.822}
                height={49.822}
                style={{
                    clipRule: "evenodd",
                    fillRule: "evenodd",
                    imageRendering: "optimizeQuality",
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                }}
                viewBox="0 0 1318.2 1318.2"
                id="svg166"
                xmlns="http://www.w3.org/2000/svg"
                {...children}
            >
                <defs id="defs20">
                    <style type="text/css" id="style18">
                        {
                            ".str1{stroke:#373435;stroke-width:52.92;stroke-miterlimit:22.9256}.fil2{fill:none}"
                        }
                    </style>
                </defs>
                <g id="Layer_x0020_1" transform="translate(-15456.04 -6247.41)">
                    <g id="_2033470154560">
                        <circle
                            className="fil2"
                            cx={16115.14}
                            cy={6906.51}
                            r={623.82}
                            id="circle146"
                            style={style}
                        />
                        <path
                            className="fil2 str1"
                            d="M16285.19 7204.94c-10.27-4.56-20.53-9.12-37.94-32.52-17.4-23.39-41.93-65.61-65.04-86.72-23.1-21.11-44.78-21.11-65.89-25.1-21.11-3.99-41.65-11.98-55.91-26.81-14.27-14.84-22.25-36.52-25.67-55.06-3.43-18.54-2.29-33.95 7.7-47.35 9.98-13.41 28.81-24.82 44.5-32.81 15.69-7.99 28.23-12.55 40.78-17.12"
                            id="path148"
                        />
                        <path
                            className="fil2 str1"
                            d="M16034.74 6978.73c-27.2-1.04-54.39-2.09-82.83-1.76-28.43.33-58.1 2.05-77.78 2.33-19.68.29-29.38-.86-46.78-13.69-17.4-12.84-42.51-37.37-54.49-61.33-11.98-23.97-10.84-47.36-2.28-69.32 8.56-21.97 24.53-42.51 41.94-55.91 17.4-13.41 36.22-19.69 52.2-23.39 15.97-3.71 29.1-4.85 43.36-1.72 14.26 3.14 29.67 10.56 38.51 16.27 8.84 5.7 11.12 9.69 13.41 13.69"
                            id="path150"
                        />
                        <path
                            className="fil2 str1"
                            d="M15886.32 6752.27c-2.82-12.28-5.65-24.56-2.79-38.68 2.87-14.13 11.43-30.1 24.83-45.22 13.41-15.12 31.67-29.39 49.35-37.95 17.69-8.55 34.8-11.41 48.5-18.25 13.69-6.85 23.96-17.69 39.08-27.39 15.12-9.7 35.09-18.25 55.34-22.53 20.25-4.28 40.79-4.28 58.19-.86 17.4 3.42 31.67 10.27 43.36 17.12 11.7 6.84 20.83 13.69 25.68 18.54 4.85 4.85 5.42 7.7 5.99 10.56"
                            id="path152"
                        />
                        <path
                            className="fil2 str1"
                            d="M16115.75 6665.8c17.12-15.98 34.23-31.95 53.91-41.65 19.69-9.7 41.94-13.12 63.61-14.26 21.69-1.14 42.79 0 65.61 7.99 22.83 7.98 47.36 22.81 64.47 39.36 17.12 16.54 26.82 34.8 34.52 53.35 7.7 18.53 13.41 37.36 16.26 53.06 2.86 15.68 2.86 28.23 2.86 40.79"
                            id="path154"
                        />
                        <path
                            className="fil2 str1"
                            d="M16199.62 6708.59c2.85 13.69 5.7 27.38 15.11 39.65 9.42 12.26 25.39 23.11 50.78 30.81 25.39 7.7 60.19 12.26 87 15.97 26.82 3.71 45.65 6.56 58.2 11.13 12.55 4.56 18.83 10.84 30.81 22.25s29.67 27.95 39.93 48.78c10.27 20.82 13.13 45.92 11.13 67.89-2 21.96-8.84 40.79-21.39 57.34-12.56 16.54-30.81 30.81-47.64 40.79-16.83 9.98-32.24 15.69-48.78 18.26-16.55 2.56-34.24 1.99-49.07 1.14-14.83-.86-26.81-2-32.8 4.28-5.99 6.27-5.99 19.96-1.43 36.79 4.57 16.83 13.69 36.8 22.25 52.49 8.56 15.69 16.54 27.1 11.69 34.52-4.84 7.42-22.53 10.84-34.92 10.31-12.4-.53-19.5-5.01-23.06-7.25l-3.55-2.24"
                            id="path156"
                        />
                        <path
                            className="fil2 str1"
                            d="M16170.52 6967.03c7.41-12.55 14.83-25.1 29.66-32.8 14.84-7.7 37.09-10.56 65.33-10.56 28.24 0 62.47 2.86 86.44-2.56 23.96-5.42 37.65-19.12 51.34-32.81"
                            id="path158"
                        />
                        <path
                            className="fil2 str1"
                            d="M16014.77 6705.16c4.56 11.42 9.12 22.82 18.25 34.23 9.13 11.42 22.82 22.82 36.52 31.1 13.69 8.27 27.38 13.41 41.07 18.54"
                            id="path160"
                        />
                        <path
                            className="fil2 str1"
                            d="M15867.57 6830.11c1.71 13.69 3.42 27.38 11.41 37.37 7.99 9.98 22.25 16.26 40.79 18.54 18.55 2.28 41.37.57 64.19-1.14"
                            id="path162"
                        />
                    </g>
                </g>
            </svg>
        </Tooltip>
    )
}