import styled from "styled-components";
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "@mui/material";


export default function TextBox({ data, loadState, className }) {
    return (
        <>
            <StyledContainer className={className}>
                <ConditionalContentBox data={data} loadState={loadState} />
            </StyledContainer>
        </>
    )
}

function ConditionalContentBox({ data, loadState }) {
    // console.log(loadState)
    // switch (loadState) {
    //     case "loading":
    //         return (<StyledLoaderContainer><CircularProgress /></StyledLoaderContainer>)
    //     case "ready":
    //         return (<p>{data.descriptions[0].description}</p>)
    //     case "ready-not-found":
    //         return (<p>No functional information available</p>)
    //     case "fail":
    //         return (<p>No functional information available</p>)
    // }
    const geneCardsComposedUrl = `https://www.genecards.org/cgi-bin/carddisp.pl?gene=${data}`

    return(
      <p>Click <a href={geneCardsComposedUrl}>here</a> for details (source: Gene Cards)</p>
    )
}

const StyledContainer = styled.div`
    height: 500px;
    overflow-y: scroll;
    font-family: 'Rubik', sans-serif;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar{
        display: none;
    }

    p{
        text-align:justify;
    }

    @media(max-width: 600px){
        height: 600px;
    }
`

const StyledLoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`